export const FormCaixaMixin = {
    props: {
      propEditedItem: {
        type: Object,
      },
      propDefaultItem: {
        type: Object,
      },
    },
    data() {
      return {
        editedItem: this.propEditedItem,
        defaultItem: this.propDefaultItem,
      }
    }
}