<template>
  <div>
    <confirm-dialog @confirm="confirmDelete" />
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="entradas"
      sort-by="id"
      class="border"
    >
      <template v-slot:[`item.valor`]="{ item }">
        <span class="positive-value">{{
          toReal(item.valor) || toReal(0)
        }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          color="primary"
          class="mr-2"
          @click="$emit('editEntrada', item)"
          v-if="item.servico_triado === null && canCreate"
          >mdi-pencil</v-icon
        >
        <v-icon
          small
          color="error"
          class="mr-2"
          @click="deleteItem(item)"
          v-if="item.servico_triado === null && canDelete"
          >mdi-delete</v-icon
        >
      </template>
      <template v-slot:[`body.append`]>
        <tr class="font-weight-bold">
          <td colspan="2">Total</td>
          <td class="positive-value">
            {{ toReal(totalEntradas) }}
          </td>
        </tr>
      </template>
      <template #no-data v-if="!canList">
        <span class="red--text"
          >Você não tem permissão para visualizar as informações desta
          tela</span
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import { toReal } from '@/mixins/convertion.js'

import ConfirmDialog from '@/components/template/ConfirmDialog'

import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  name: 'EntradaRelatorioCaixa',
  mixins: [toReal],
  components: { ConfirmDialog },
  data() {
    return {
      dialog: null,
      headers: [
        { text: 'Descricao', value: 'descricao' },
        { text: 'Valor', value: 'valor' },
        { text: 'Forma Pagamento', value: 'forma_pagamento.descricao' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      item: {},
    }
  },
  computed: {
    ...mapState('caixa', ['caixaAberto']),
    ...mapGetters('caixa', ['entradas', 'totalEntradas']),
    permission() {
      return Sequences.Caixa.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },

  methods: {
    ...mapActions('caixa', ['deleteMovimentacao']),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),

    deleteItem(item) {
      this.item = item
      this.setDialogMessage({
        title: 'Confirmar exclusão?',
        message: 'Deseja realmente excluir esta movimentação?',
      })
    },
    async confirmDelete() {
      try {
        await this.deleteMovimentacao(this.item.id)
        this.successMessage('Movimentação excluída com sucesso!')
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
  },
}
</script>
