<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <validation-provider v-slot="{ errors }" name="valor" rules="required">
          <vuetify-money
            v-model="value.valor_em_caixa"
            :error-messages="errors"
            :label="'Valor Inicial do Caixa'"
            :properties="{
              'persistent-hint': true,
              'prepend-icon': 'mdi-currency-usd',
              required: true,
            }"
          />
        </validation-provider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { FormCaixaMixin } from '@/mixins/formCaixa.js'
export default {
  name: 'FormEntradaRelatorioCaixa',
  mixins: [FormCaixaMixin],
  props: { value: { required: true, type: Object } },
  data() {
    return {}
  },

  methods: {},
}
</script>
