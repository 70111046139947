<template>
  <conferencia-base-dialog
    max-width="1200px"
    :value="value"
    @close="finishResume"
  >
  
    <template #title> Resumo - Envelope Nº {{ item.numero }} </template>
    <template #subtitle> <b>Colaborador:</b> {{ item.colaborador.toUpperCase() }}  <b>Data Abertura:</b> {{ item.data_hora_abertura | toLocaleDateTimeString }}</template>

    <template #text>
      <v-row justify="center">
        <v-col>
          <conferencia-resumo-forma-pagamento
            class="mt-4"
            :data="resumoFormaPagamento"
          />
        </v-col>
        <v-col>
          <conferencia-resumo-servico class="mt-4" :data="resumoServico" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <conferencia-resumo-total
            class="mt-4"
            :totais="totais"
            :resumoServico="resumoServico"
          />
        </v-col>
      </v-row>
    </template>
    <template #actions><br /> </template>
  </conferencia-base-dialog>
</template>

<script>
import { mapActions } from 'vuex'

import ConferenciaBaseDialog from '@/components/financeiro/conferencia/ConferenciaBaseDialog.vue'
import ConferenciaResumoFormaPagamento from '@/components/financeiro/conferencia/conferencia-resumo/ConferenciaResumoFormaPagamento.vue'
import ConferenciaResumoServico from '@/components/financeiro/conferencia/conferencia-resumo/ConferenciaResumoServico.vue'
import ConferenciaResumoTotal from '@/components/financeiro/conferencia/conferencia-resumo/ConferenciaResumoTotal.vue'
import { toLocaleDateTimeString } from '@/mixins/convertion'

export default {
  name: 'ConferenciaResumo',
  inject: ['finishResume'],
  props: {
    value: {
      type: Boolean,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    ConferenciaBaseDialog,
    ConferenciaResumoFormaPagamento,
    ConferenciaResumoServico,
    ConferenciaResumoTotal,
  },
  filters: {
    toLocaleDateTimeString,
  },
  data() {
    return {
      resumoFormaPagamento: [],
      resumoServico: [],
      totais: {},
    }
  },
  methods: {
    ...mapActions('caixa', ['getResumoCaixa']),
    ...mapActions('template', ['errorMessage', 'successMessage']),
  },
  
  async created() {
    try {
      const {
        resumo_forma_pagamento,
        resumo_servico,
        total_caminhoes,
        total_entrada_manual,
        total_retirada,
      } = await this.getResumoCaixa(this.item.id)
      this.resumoFormaPagamento = resumo_forma_pagamento
      this.resumoServico = resumo_servico
      this.totais = {
        total_em_caixa: this.item.valor_em_caixa || 0,
        total_caminhoes,
        total_entrada_manual,
        total_retirada,
      }
    } catch (e) {
      this.errorMessage(e.response.data)
    }
  },
}
</script>
