<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    />
    <v-row>
      <v-col cols="12" sm="12"
        ><pesquisa-relatorio-caixa></pesquisa-relatorio-caixa
      ></v-col>
    </v-row>
    <v-container fluid mb-5>
      <v-row justify="space-between" v-if="temCaixaAberto">
        <v-col>
          <v-btn
            v-if="canCreate"
            color="error"
            class="mx-5"
            @click="submitFecharCaixa"
            >Fechar Caixa</v-btn
          >
          <v-btn
            v-if="canList"
            color="info"
            class="mx-5"
            @click="showResumo = true"
            >Resumo De Caixa</v-btn
          >
          <router-link
            :to="{ name: 'extrato', params: { caixaID: caixaAberto.id } }"
            ><v-btn v-if="canList" color="info" class="mx-5"
              >Extrato</v-btn
            ></router-link
          >
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn
            v-if="canCreate"
            color="primary"
            class="mx-5"
            @click="dialogSaida = true"
            >Retirar</v-btn
          >
          <v-btn
            v-if="canCreate"
            color="primary"
            class="mx-5"
            @click="dialogEntrada = true"
            >Adicionar</v-btn
          >
        </v-col>
      </v-row>
      <v-row justify="center" v-else>
        <v-col class="d-flex justify-center">
          <v-btn
            v-if="canCreate && canList"
            color="error"
            class=""
            @click="dialogAbrirCaixa = true"
            >Abrir Caixa</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
    <div>
      <resumo v-if="showResumo" v-model="showResumo" :item="caixaAberto" />

      <v-dialog v-model="dialogAbrirCaixa" scrollable>
        <form-caixa-relatorio-caixa
          ref="FormAbrirCaixa"
          @closeAbrirCaixa="closeAbrirCaixa"
          :dialogClose="'closeAbrirCaixa'"
          :formTitle="'Abrir Caixa'"
          @save="submitAbrirCaixa"
        >
          <template v-slot:form>
            <form-abrir-caixa
              ref="FormFieldsAbrirCaixa"
              v-model="novoCaixa"
            ></form-abrir-caixa>
          </template>
        </form-caixa-relatorio-caixa>
      </v-dialog>
      <v-dialog v-model="dialogSaida" scrollable>
        <form-caixa-relatorio-caixa
          ref="FormSaida"
          @closeSaida="closeSaida"
          v-bind:dialogClose="'closeSaida'"
          v-bind:formTitle="'Saída'"
          @save="submitRetiradaCaixa"
        >
          <template v-slot:form>
            <form-retirada-caixa
              ref="FormFieldsSaida"
              v-model="itemRetirada"
            ></form-retirada-caixa>
          </template>
        </form-caixa-relatorio-caixa>
      </v-dialog>
      <v-dialog v-model="dialogEntrada" scrollable>
        <form-caixa-relatorio-caixa
          ref="FormEntrada"
          @close="closeEntrada"
          v-bind:dialogClose="'close'"
          v-bind:formTitle="'Entrada'"
          @save="submitEntradaCaixa"
        >
          <template v-slot:form>
            <form-entrada-caixa
              ref="FormFieldsEntrada"
              v-model="itemEntrada"
            ></form-entrada-caixa>
          </template>
        </form-caixa-relatorio-caixa>
      </v-dialog>
    </div>
    <v-row v-if="temCaixaAberto && canList">
      <v-col cols="12" sm="12">
        <v-card>
          <v-card-title>
            <span>Envelope:</span>
            <v-spacer></v-spacer>
            <span>{{ dataAberturaCaixa }}</span>
            <v-spacer></v-spacer>
            <span>{{ caixaAberto.numero }}</span>
          </v-card-title>
          <v-card-text>
            <v-expansion-panels v-model="panel" multiple>
              <v-expansion-panel>
                <v-expansion-panel-header class="my-4"
                  >Entrada</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <entrada-relatorio-caixa
                    @editEntrada="editEntrada"
                  ></entrada-relatorio-caixa>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header class="my-4"
                  >Saida</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <saida-relatorio-caixa
                    @editSaida="editSaida"
                  ></saida-relatorio-caixa>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>Resumo</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Entradas</th>
                          <th class="text-left">Saídas</th>
                          <th class="text-left">Saldo Final</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="font-weight-bold">
                          <td class="positive-value">
                            {{ toReal(totalEntradas) }}
                          </td>
                          <td class="negative-value">
                            {{ toReal(totalSaidas) }}
                          </td>
                          <td
                            :class="
                              total >= 0 ? 'positive-value' : 'negative-value'
                            "
                          >
                            {{ toReal(total) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels></v-card-text
          ></v-card
        >
      </v-col>
    </v-row>
    <v-row v-if="!canList">
      <v-col cols="12" sm="12">
        <v-card align="center">
          <span class="red--text"
            >Você não tem permissão para visualizar as informações desta
            tela</span
          >
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

import { toReal, parseDatetime } from '@/mixins/convertion.js'
import { TipoMovimentacao } from '@/utils/faturamento'
import { BasicPermissions, Sequences } from '@/utils/permissions'

import PesquisaRelatorioCaixa from '@/components/financeiro/caixa/relatorio/Pesquisa.vue'
import EntradaRelatorioCaixa from '@/components/financeiro/caixa/relatorio/Entrada.vue'
import SaidaRelatorioCaixa from '@/components/financeiro/caixa/relatorio/Saida.vue'
import FormRetiradaCaixa from '@/components/financeiro/caixa/forms/RetiradaCaixa.vue'
import FormEntradaCaixa from '@/components/financeiro/caixa/forms/EntradaCaixa.vue'
import FormAbrirCaixa from '@/components/financeiro/caixa/forms/AbrirCaixa.vue'
import FormCaixaRelatorioCaixa from '@/components/financeiro/caixa/relatorio/FormCaixa.vue'
import Resumo from './Resumo.vue'

export default {
  name: 'Relatorio',
  provide() {
    return {
      finishResume: this.finishResume,
    }
  },

  components: {
    PesquisaRelatorioCaixa,
    EntradaRelatorioCaixa,
    SaidaRelatorioCaixa,
    FormRetiradaCaixa,
    FormEntradaCaixa,
    FormCaixaRelatorioCaixa,
    FormAbrirCaixa,
    Resumo,
  },
  mixins: [toReal, parseDatetime],
  data: function () {
    return {
      dialogSaida: false,
      dialogEntrada: false,
      dialogAbrirCaixa: false,
      showResumo: false,
      novoCaixa: {
        valor_em_caixa: 0,
      },
      itemRetirada: {
        descricao: '',
        forma_pagamento: '',
        valor: '',
      },
      itemEntrada: {
        descricao: '',
        forma_pagamento: '',
        valor: '',
      },
      panel: [0, 1, 2],
      page: {
        title: 'Relatório de Caixa',
      },
      breadcrumbs: [
        {
          text: 'Financeiro',
          disabled: true,
        },
        {
          text: 'Caixa',
          disabled: true,
        },
        {
          text: 'Relatório',
          disabled: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('caixa', ['caixaAberto']),
    ...mapGetters('caixa', ['temCaixaAberto', 'totalEntradas', 'totalSaidas']),
    dataAberturaCaixa() {
      return new Date(this.caixaAberto.data_hora_abertura).toLocaleString()
    },
    total() {
      return this.totalEntradas - this.totalSaidas
    },
    permission() {
      return Sequences.Caixa.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  async created() {
    if (this.canList) {
      await this.getMeuCaixaAberto()
    }
  },
  methods: {
    ...mapActions('caixa', [
      'getMeuCaixaAberto',
      'abrirCaixa',
      'fecharCaixa',
      'retiradaCaixa',
      'entradaCaixa',
      'editMovimentacao',
    ]),
    ...mapActions('template', ['errorMessage', 'successMessage']),

    finishResume() {
      this.showResumo = false
    },

    async submitAbrirCaixa() {
      try {
        await this.abrirCaixa({ ...this.novoCaixa })
        this.successMessage('Caixa aberto com sucesso.')
        this.closeAbrirCaixa()
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
    async submitFecharCaixa() {
      try {
        await this.fecharCaixa()
        this.successMessage('Caixa fechado com sucesso.')
      } catch (e) {
        this.errorMessage('Erro ao fechar o caixa.')
      }
    },
    editSaida(item) {
      this.dialogSaida = true
      this.itemRetirada = {
        ...item,
        forma_pagamento: item.forma_pagamento.public_id,
      }
    },
    editEntrada(item) {
      this.dialogEntrada = true
      this.itemEntrada = {
        ...item,
        forma_pagamento: item.forma_pagamento.public_id,
      }
    },
    async submitRetiradaCaixa() {
      try {
        if (this.itemRetirada.id) {
          await this.editMovimentacao(this.itemRetirada)
        } else {
          await this.retiradaCaixa({
            ...this.itemRetirada,
            caixa: this.caixaAberto.id,
            tipo: TipoMovimentacao.Saida.id,
          })
        }
        this.successMessage('Retirada realizada com sucesso.')
        this.closeSaida()
      } catch (e) {
        this.errorMessage('Erro ao realizada retirada.')
      }
    },
    async submitEntradaCaixa() {
      try {
        if (this.itemEntrada.id) {
          await this.editMovimentacao(this.itemEntrada)
        } else {
          await this.entradaCaixa({
            ...this.itemEntrada,
            caixa: this.caixaAberto.id,
            tipo: TipoMovimentacao.Entrada.id,
          })
        }
        this.successMessage('Entrada realizada com sucesso.')
        this.closeEntrada()
      } catch (e) {
        this.errorMessage('Erro ao realizar entrada.')
      }
    },
    closeSaida() {
      this.itemRetirada = { descricao: '', valor: '', forma_pagamento: '' }
      this.dialogSaida = false
    },
    closeEntrada() {
      this.itemEntrada = { descricao: '', valor: '', forma_pagamento: '' }
      this.dialogEntrada = false
    },
    closeAbrirCaixa() {
      this.novoCaixa = {
        valor_em_caixa: 0,
      }
      this.dialogAbrirCaixa = false
    },
  },
}
</script>
