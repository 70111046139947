<template>
  <v-card tile>
    <v-card-text>
      <v-container>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <form>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <slot name="form"></slot>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="invalid"
                @click="$emit('save')"
                >Salvar</v-btn
              >
            </v-card-actions>
          </form>
        </validation-observer>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
// import { FormCaixaMixin } from '@/mixins/formCaixa.js'
// import { toReal } from '@/mixins/convertion.js'
export default {
  name: 'FormCaixaRelatorioCaixa',
  //   mixins: [FormCaixaMixin, toReal],
  props: {
    dialogClose: {
      type: String,
      required: true,
    },
    formTitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {},

  watch: {},

  async created() {},

  methods: {
    close() {
      this.$emit(this.dialogClose)
      //   Object.assign(this.editedItem, this.defaultItem)
    },
  },
}
</script>
