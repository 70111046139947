<template>
  <v-row>
    <v-col cols="4">
      <validation-provider
        v-slot="{ errors }"
        name="descricao"
        rules="required"
      >
        <v-text-field
          v-model="value.descricao"
          label="Descrição/Motivo"
          hint="Diga, em poucas palavras, o motivo da retirada. Ex.: Almoço do Fulano"
          persistent-hint
          prepend-icon="mdi-clipboard-text"
          required
          :error-messages="errors"
        ></v-text-field>
      </validation-provider>
    </v-col>
    <v-col cols="4">
      <validation-provider
        v-slot="{ errors }"
        name="forma_pagamento"
        rules="required"
      >
        <v-autocomplete
          v-model="value.forma_pagamento"
          label="Forma Pagamento"
          prepend-icon="mdi-cash-multiple"
          required
          :error-messages="errors"
          item-text="descricao"
          item-value="public_id"
          :items="formasPagamentoNotConvenio"
        />
      </validation-provider>
    </v-col>
    <v-col cols="4">
      <validation-provider v-slot="{ errors }" name="valor" rules="required">
        <vuetify-money
          v-model="value.valor"
          v-bind:label="'Valor da Saída'"
          v-bind:properties="{
            'prepend-icon': 'mdi-currency-usd',
            required: true,
          }"
          :error-messages="errors"
        />
      </validation-provider>
    </v-col>
  </v-row>
</template>

<script>
import { FormCaixaMixin } from '@/mixins/formCaixa.js'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'FormSaidaRelatorioCaixa',
  mixins: [FormCaixaMixin],
  props: { value: { type: Object, required: true } },
  computed: {
    ...mapGetters('formaPagamento', ['formasPagamentoNotConvenio']),
  },
  methods: {
    ...mapActions('formaPagamento', ['getFormasPagamento']),
    ...mapActions('template', ['errorMessage']),
  },
  async created() {
    try {
      await this.getFormasPagamento()
    } catch (e) {
      this.errorMessage(e.response.data)
    }
  },
}
</script>
