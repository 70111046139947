var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"descricao","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Descrição/Motivo","hint":"Diga, em poucas palavras, o motivo da retirada. Ex.: Almoço do Fulano","persistent-hint":"","prepend-icon":"mdi-clipboard-text","required":"","error-messages":errors},model:{value:(_vm.value.descricao),callback:function ($$v) {_vm.$set(_vm.value, "descricao", $$v)},expression:"value.descricao"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"forma_pagamento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Forma Pagamento","prepend-icon":"mdi-cash-multiple","required":"","error-messages":errors,"item-text":"descricao","item-value":"public_id","items":_vm.formasPagamentoNotConvenio},model:{value:(_vm.value.forma_pagamento),callback:function ($$v) {_vm.$set(_vm.value, "forma_pagamento", $$v)},expression:"value.forma_pagamento"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"valor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vuetify-money',{attrs:{"label":'Valor da Saída',"properties":{
          'prepend-icon': 'mdi-currency-usd',
          required: true,
        },"error-messages":errors},model:{value:(_vm.value.valor),callback:function ($$v) {_vm.$set(_vm.value, "valor", $$v)},expression:"value.valor"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }