<template>
  <v-container fluid>
    <v-row align="center" v-if="canList">
      <v-col class="d-flex" cols="12" sm="4">
        <v-select
          :items="colaboradores"
          item-text="user.name"
          item-value="public_id"
          return-object
          filled
          label="Colaborador"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4">
        <v-datetime-picker
          v-model="date_inital"
          label="Data inicial"
          hint="Data inicial da pesquisa."
          persistent-hint
          :rules="rules"
          dateFormat="dd/MM/yyyy"
          :textFieldProps="{ rules: rules }"
        >
          <template slot="dateIcon">
            <v-icon>mdi-calendar</v-icon>
          </template>
          <template slot="timeIcon">
            <v-icon>mdi-clock</v-icon>
          </template>
        </v-datetime-picker>
      </v-col>

      <v-col cols="12" sm="4">
        <v-datetime-picker
          v-model="date_final"
          label="Data inicial"
          hint="Data inicial da pesquisa."
          persistent-hint
          :textFieldProps="{ rules: rules }"
          dateFormat="dd/MM/yyyy"
        >
          <template slot="dateIcon">
            <v-icon>mdi-calendar</v-icon>
          </template>
          <template slot="timeIcon">
            <v-icon>mdi-clock</v-icon>
          </template>
        </v-datetime-picker>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { TIME_DAYS } from '@/mixins/days.js'
import { mapActions, mapState } from 'vuex'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  name: 'PesquisaRelatorioCaixa',
  tela: {
      type: String,
      required: true,
  },
  data() {
    return {
      items: [],
      date_inital: new Date(Date.now()),
      date_final: new Date(Date.now() + TIME_DAYS(1)),
      rules: [
        () => this.date_inital < this.date_final || 'Data inválida',
        (v) =>
          new Date(this.parseDate(v.split(' ')[0])) >=
            new Date(
              this.parseDate(
                new Date(new Date() - TIME_DAYS(1)).toLocaleDateString()
              )
            ) || 'Data mínima requerida!',
      ],
    }
  },
  computed: {
    ...mapState('gerencial', ['colaboradores']),
    data_inicial() {
      return this.formatDate(this.date_inital)
    },
    data_final() {
      return this.formatDate(this.date_final)
    },
    permission() {
      return Sequences.Caixa.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
  },
  async created() {
    if (this.empresa) {
      await this.getColaboradoresDaEmpresa(this.empresa)
    } else {
      await this.getColaboradores()
    }
  },
  methods: {
    ...mapActions('gerencial', [
      'getColaboradores',
      'getColaboradoresDaEmpresa',
    ]),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },
}
</script>
