<template>
  <div>
    <confirm-dialog @confirm="confirmDelete" />
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="saidas"
      sort-by="id"
      class="border"
    >
      <template v-slot:[`item.valor`]="{ item }">
        <span class="negative-value">{{
          toReal(item.valor) || toReal(0)
        }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          v-if="canCreate"
          small
          color="primary"
          class="mr-2"
          @click="$emit('editSaida', item)"
          >mdi-pencil</v-icon
        >
        <v-icon v-if="canDelete" small color="error" @click="deleteItem(item)"
          >mdi-delete</v-icon
        >
      </template>
      <template v-slot:[`body.append`]>
        <tr class="font-weight-bold">
          <td>Total</td>
          <td class="negative-value">{{ toReal(totalSaidas) }}</td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { toReal } from '@/mixins/convertion.js'

import ConfirmDialog from '@/components/template/ConfirmDialog'

import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  name: 'SaidaRelatorioCaixa',
  mixins: [toReal],
  components: { ConfirmDialog },
  data() {
    return {
      dialog: null,
      headers: [
        { text: 'Descrição', value: 'descricao' },
        { text: 'Valor', value: 'valor' },
        { text: 'Forma de Pagamento', value: 'forma_pagamento.descricao' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
    }
  },
  computed: {
    ...mapGetters('caixa', ['saidas', 'totalSaidas']),
    permission() {
      return Sequences.Caixa.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  methods: {
    ...mapActions('caixa', ['deleteMovimentacao']),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),

    deleteItem(item) {
      this.item = item
      this.setDialogMessage({
        title: 'Confirmar exclusão?',
        message: 'Deseja realmente excluir esta movimentação?',
      })
    },
    async confirmDelete() {
      try {
        await this.deleteMovimentacao(this.item.id)
        this.successMessage('Movimentação excluída com sucesso!')
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
  },
}
</script>
